'use strict';

import reduce from 'lodash/reduce';

// limit queries to the DOM, to avoid slowing down the app
const DOM_CHECK_INTERVAL = 2000;

/*
 * Forces panels of the accordion to respect its height
 * and not extend beyond that. Needs to be placed in a
 * container that wraps the accordion (because it must
 * run after the accordion has finished);
 */
class TsAccordionHeightFixController {
    constructor($element) {
        'ngInject';

        this.$element = $element;
    }

    setHeadings(headings) {
        this.headings = headings;
    }

    setAccordion(accordion) {
        this.accordion = accordion;
    }

    getHeadingsHeightWithMargins() {
        return reduce(
            this.headings,
            (sum, element) => {
                const computedStyle = window.getComputedStyle(element);
                const heightWithPaddings = element.clientHeight;
                const marginTop = parseFloat(computedStyle.marginTop);
                const marginBottom = parseFloat(computedStyle.marginBottom);

                return sum + heightWithPaddings + marginTop + marginBottom;
            },
            0
        );
    }

    checkPanelMax() {
        this.getElements();

        const headingsHeightWithMargins = this.getHeadingsHeightWithMargins();
        const panelBody = this.accordion.find('.panel-body');
        const panelPaddingTop = parseFloat(panelBody.css('padding-top'));
        const panelPaddingBottom = parseFloat(panelBody.css('padding-bottom'));
        const accordionHeight = this.accordion.height();

        this.panelMax = accordionHeight - headingsHeightWithMargins - panelPaddingTop - panelPaddingBottom;

        return this.panelMax;
    }

    getElements() {
        this.accordion = this.$element.find('accordion').eq(0);

        if (!this.accordion || !this.accordion.height()) {
            return;
        }

        this.setHeadings(this.accordion.find('.panel-heading'));
        this.setAccordion(this.accordion);
    }
}

function linkFn(scope, element, attrs, ctrl) {
    const checkInterval = setInterval(() => {
        const panelMax = ctrl.checkPanelMax();

        if (panelMax > 0) {
            ctrl.accordion.find('.panel-body').attr('style', `max-height: ${panelMax}px !important`);
        }
    }, DOM_CHECK_INTERVAL);

    scope.$on('$destroy', () => clearInterval(checkInterval));
}

export function tsAccordionHeightFixDirective() {
    return {
        restrict: 'A',
        scope: false,
        controller: TsAccordionHeightFixController,
        controllerAs: 'vm',
        link: linkFn
    };
}
